import React from 'react';

//import Layout from '../components/layout';
import { StaticQuery, graphql } from "gatsby"
import PortfolioCard from "../components/portfolioNew"
import HomeVideo from "../videos/home-hero.mp4"
import HomeHero from "../videos/home-hero.jpg"
import { motion } from "framer-motion"
import ContextConsumer from "../components/context"
import ContextUpdater from "../components/context_updater"

// Decode HTML from server
const renderHTML = (rawHTML) => {
    return React.createElement(
        "span",
        { dangerouslySetInnerHTML: { __html: rawHTML } }
    );
}

const IndexPage = ({data}) => {
	const handlePortfolioClick = (work) => {
		//alert("portfolio clicked: " + work.node.title);
	}

	var header_text_colour="#f1cfbc";
	var text_colour="#f1cfbc";
	var body_colour="#134140";	
    var stroke_width="20"
    var animation_delay_base = 0.5;
    var animation_duration_base = 0.75;

    // Animate illustration
    const line_animation = {
      hidden: { pathLength: 0, pathOffset: 1},
      visible: { pathLength: 1, pathOffset: 0}
    }

	// Move this to navbar
	if (data.settings.edges[0].node.home_light_or_dark == "light"){
		var logo = data.settings.edges[0].node.localLogoLight;
	} else {
		var logo = data.settings.edges[0].node.localLogoDark;
	}

	// Check for SVG Logo
	if (data.settings.edges[0].node.svg_logo){
		var svg_logo = data.settings.edges[0].node.svg_logo;
	} else {
		var svg_logo = false;
	}

	return (
  		<ContextConsumer>
		{cdata => 
			<>
			<ContextUpdater
				context={cdata}
				newContext={
				  {
					body_colour: body_colour,
					header_text_colour: header_text_colour,
					text_colour: text_colour,
					header_image: false,
				  }
				}
			/>
        	<div className="container">
          		<div className="home-header">
              		<div className="columns is-vcentered">
                		<div className="column is-half">
							<p className="lead">{ renderHTML(data.settings.edges[0].node.intro) }</p>
						</div>
                		<div id="home-svg" className="column is-half svgFade">

                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 500">
                            <g id="outer_frame">
                              <motion.path
                                id="of_1"
                                d="M9.76,9.56h280v480H9.76V-0.32Z"
                                transform="translate(0.24 0.44)"
                                stroke={header_text_colour}
                                strokeWidth={stroke_width}
								//style={{opacity: 0}}
                                fill="transparent"
                                variants={line_animation}
                                initial="hidden"
                                animate="visible"
                                transition={{ duration: animation_duration_base * 2 }}
                              />
                            </g>
                            <g id="inner_frame">
                              <motion.path
                                 id="if_1"
                                 d="M 10.24,130.44 H 290.23999"
                                 stroke={header_text_colour}
                                 strokeWidth={stroke_width}
                                 fill="transparent"
                                 variants={line_animation}
                                 initial="hidden"
                                 animate="visible"
                                 transition={{ duration: animation_duration_base, delay: animation_delay_base * 2 }}
                              />
                              <motion.path
                                 id="if_2"
                                 d="M 10.24,250.44 H 290.23999"
                                 stroke={header_text_colour}
                                 strokeWidth={stroke_width}
                                 fill="transparent"
                                 variants={line_animation}
                                 initial="hidden"
                                 animate="visible"
                                 transition={{ duration: animation_duration_base, delay: animation_delay_base * 3 }}
                              />
                              <motion.path
                                 id="if_3"
                                 d="M 10.24,370.44 H 290.23999"
                                 stroke={header_text_colour}
                                 strokeWidth={stroke_width}
                                 fill="transparent"
                                 variants={line_animation}
                                 initial="hidden"
                                 animate="visible"
                                 transition={{ duration: animation_duration_base, delay: animation_delay_base * 4 }}
                              />
                            </g>
                            <g id="decorations">
                              <motion.path
                                 id="upright"
                                 d="m 150.24001,490.44 v -120"
                                 stroke={header_text_colour}
                                 strokeWidth={stroke_width}
                                 fill="transparent"
                                 variants={line_animation}
                                 initial="hidden"
                                 animate="visible"
                                 transition={{ duration: animation_duration_base, delay: animation_delay_base * 5 }}
                              />
                              <motion.path
                                 id="arc"
                                 d="m 16.05,250.44 a 140,140 0 0 0 268.38,0"
                                 stroke={header_text_colour}
                                 strokeWidth={stroke_width}
                                 fill="transparent"
                                 variants={line_animation}
                                 initial="hidden"
                                 animate="visible"
                                 transition={{ duration: animation_duration_base, delay: animation_delay_base * 6 }}
                              />
                              <motion.path
                                 id="triangle"
                                 d="m 20.24,10.44 129.76,210 130.24,-210 h -260"
                                 stroke={header_text_colour}
                                 strokeWidth={stroke_width}
                                 fill="transparent"
                                 variants={line_animation}
                                 initial="hidden"
                                 animate="visible"
                                 transition={{ duration: animation_duration_base * 2, delay: animation_delay_base * 7 }}
                              />
                            </g>
                            Sorry, your browser does not support inline SVG.  
                          </svg>

						</div>
					</div>
				</div>
			</div>
				<div id="#work" className="uk-section">
				  <div className="container uk-container-large">
					<div className="columns is-multiline is-vcentered">
					{ data.works.edges.map((article, i) => {
					  return (
						<PortfolioCard
							work={article}
							count={i}
							full={i % 3 == 0}
							click={handlePortfolioClick}
							key={`article__${article.node.pk}`}
							loading={i == 0 ? "eager" : "lazy"}
						/>
					  )
					}) }

					</div>
				  </div>
				</div>
			</>
		}
  		</ContextConsumer>
	)
}

export const query = graphql`
  query {
	settings: allRestApiSettings {
	  edges {
		node {
		  title
		  intro
		  home_body_colour
		  home_body_shade
		  home_header_text_colour
		  home_light_or_dark
		  home_text_colour
		  svg_logo
		  localLogoDark {
			  childImageSharp {
				  fluid(maxWidth: 1360) {
					  ...GatsbyImageSharpFluid
				  }
			  }
		  }
		  localLogoLight {
			  childImageSharp {
				  fluid(maxWidth: 1360) {
					  ...GatsbyImageSharpFluid
				  }
			  }
		  }
		}
		}
	  }
	  works: allRestApiWork(filter: {active: {eq: true}}, sort: {fields: date, order: DESC}) {	
		edges {
		  node {
			pk
			active
			title
			slug
			blurb
			date
			body_colour
			text_colour
			header_text_colour
			image
			localImage {
				childImageSharp {
					fluid(maxWidth: 1800) {
					    ...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		  }
		}
	  }
	}
`


export default IndexPage;
